<template>
  <div class="min-h-screen flex flex-col">
    <div class="container mx-auto max-w-7xl">
      <header class="flex justify-between items-center p-4 xl:px-0">
        <router-link class="" to="/"
          ><img src="../assets/logo.png" alt="" width="200"
        /></router-link>
      </header>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
