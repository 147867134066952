<template>
  <auth-layout>
    <div class="flex-1 flex flex-col justify-center items-center">
      <div class="md:flex transform -translate-y-20">
        <div class="text-4xl font-bold mr-4 text-yellow-600">403</div>
        <div>
          <h1 class="text-4xl font-bold">Interzis.</h1>
          <h2 class="text-xl text-gray-400 mt-2">
            Ne pare rau, nu aveti access la pagina.
          </h2>
          <router-link
            to="/"
            class="
              inline-block
              mt-6
              px-5
              py-2
              bg-yellow-600
              text-white
              rounded-md
            "
            >Acasa</router-link
          >
        </div>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from "../layout/AuthLayout.vue";
export default {
  components: { AuthLayout },
};
</script>

<style></style>
